<style lang="less" scoped>
.detailBox {
    min-height: 500px;
    margin:0 auto 20px;
    background: rgba(5, 133, 197, 0.7);
    border-radius: 10px;
    box-shadow: 0 0 10px #000000;
    width: 65vw;
    padding-top: 30px;
    padding-bottom: 28px;
}
.detailTitle {
    margin:0;
    padding: 30px;
    text-align: center;
}
.imgWrap {
    padding: 0 30px;
    // border: solid #888 1px;
    >div {
        width: 32%;
        height: 19vw;
        // border: solid red 1px;
        // background: magenta;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        margin: 10px 0;
        float: left;
        margin-left: 1%;
        margin-bottom:0.5%;
        cursor: pointer;
        position: relative;
        >p {
            position: absolute;
            left: 0;
            bottom: -25px;
        }
    }
}
.detainMain {
    width: 100%;
    color: #fff !important;
    padding: 0 40px;
}
.width1000 {
    max-width: 65vw;
    margin: auto;
}
.newsImg {
    width: 45%;
    height: 35vw;
    float: left;
    overflow:hidden;
    margin-left: 3vw;
    margin-top: 3vw;
    >img {
        height: 100%;
        width: auto;
    }
}
.whiteColor2 {
    color: #fff !important;
    p,li,a,span {
        color: #fff !important;
    }
}
p,li,a,span {
    color: #fff !important;
}
.textBox {
    padding: 0 40px;
    float: left;
}
.pad30 {
    padding: 0 30px;
}
.twoBig {
    display: block;
    margin: auto;
}
.curImgBox {
    float: left;
    width: 100%;
}
@media (min-width:419px) {
    .width30 {
        width: 25%;
        >div>div {
            width: 100% !important;
            height: 10vw !important;
        }
        >div { 
            margin-right: -30px !important;
        }
    }
    .width701 {
        width: 58%;
    }
}

.culTitle {
    line-height: 1.3em;
    text-align: center;
    padding: 0 30px;
}
</style>

<template>
    <div style="padding-top: 100px;">
        <div class="detailBox newsBg whiteColor2">
            <h1 class="culTitle">{{title}}</h1>
            <br>
            <br>
            <div class="clearBoth">
                <div :class="['curImgBox', { 
                        width30:title=='Housing Society Seminar'
                              ||title=='Building Department Technical Exchange'
                              ||title=='Housing Authority Presentation Summary'
                              ||title=='Public Seminar in The Hong Kong Polytechnic University'
                              ||title=='International Conference and Exhibition for Trenchless Technologies and Water & Wastewater Industries 2011'
                              ||title=='Trenchless Technologies in Asia Pacific – Hong Kong 2009'
                     }]">
                    <div v-if="title == '10th Annviversary'" class="pad30">
                        <img v-for="(img,i) in list" :key="i" @click="preview(i)"
                            class="twoBig" :src="img">
                    </div>
                    <div v-else-if="title == 'Technical exchange with CACP from China'"></div>
                    <div v-else class="imgWrap clearBoth">
                        <div v-for="(img,i) in list" :key="i"  @click="preview(i)"
                            :style="{background:`url(${img})`}">
                        </div>
                    </div>
                </div>
                

                <div :class="['textBox',  { 
                    width701:title=='Housing Society Seminar' 
                    ||title=='Building Department Technical Exchange'
                    ||title=='Housing Authority Presentation Summary'
                    ||title=='Public Seminar in The Hong Kong Polytechnic University'
                    ||title=='International Conference and Exhibition for Trenchless Technologies and Water & Wastewater Industries 2011'
                    ||title=='Trenchless Technologies in Asia Pacific – Hong Kong 2009'
                }]">
                    <br>
                    <div style="margin-bottom: 1em;" v-for="(item) in text" :key="item" v-html="item"></div>
                </div>
            </div>
            


        </div>
        <br>
        <div class="width1000">
            <div class="sb">
               <div class="blueBtn" @click="back">
                    &lt; Back
                </div> 
            </div>
            
        </div>
        <div class="width1000">
            <br>
            <br>
            <br><br>
            <br><br>
            <br>
            <myFooter></myFooter>
        </div>


        <transition name="fade">
            <div v-if="showImgs">
                <div class="loopMask"></div>
                <div class="loopBox z10 ">
                    <el-carousel ref="loop" :autoplay="false" loop height="500px" class="relative z10" :initial-index="currentImg" @change="loopChange">
                        <el-carouselItem v-for="(item,i) in list" :key="i">
                            <div class="roopImg" :style="{background: `url('${item}') no-repeat`}"></div>
                        </el-carouselItem>
                    </el-carousel>
                    <div class="al miar20 sb">
                        <div class="al">
                            <div class="twoBtn al">
                                <div @click="preNext('prev')"></div>
                                <div @click="preNext('next')"></div>
                            </div>
                            <div style="margin-left: 20px">{{currentImg + 1}} / {{list.length}}</div>
                        </div>
                        <div class="close ju al" @click="showImgs=false">
                            <img src="@/assets/img/close.png" alt="">
                        </div>
                    </div>

                    <!-- <div class="loopNav">
                        <div style="width: 100%;height: 100%">
                            <myNav :list="item.big_image" :active="currentImg"></myNav>
                        </div>
                        
                    </div> -->
                </div>
            </div>
        </transition>


        
        
    </div>
</template>

<script>
export default {
    data () {
        return {
            title: "",
            list: [],
            text: [],
            showImgs: false,
            currentImg: 0,
        }
    },
    created () {
        let item = this.$route.params.item
        console.log(item)
        if (item) {

            this.list = item.img
            this.text = item.text
            this.title = item.title
        }
    },
    methods:{
        preNext (method) {
            this.$refs.loop[method]()
        },
        loopChange (e) {
            this.currentImg = e
        },
        preview (i) {
            this.showImgs = true
            this.currentImg = i
        },
        back () {
            this.$router.back()
        },
    }
}
</script>

