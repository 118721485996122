var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-top":"100px"}},[_c('div',{staticClass:"detailBox newsBg whiteColor2"},[_c('h1',{staticClass:"culTitle"},[_vm._v(_vm._s(_vm.title))]),_c('br'),_c('br'),_c('div',{staticClass:"clearBoth"},[_c('div',{class:['curImgBox', { 
                    width30:_vm.title=='Housing Society Seminar'
                          ||_vm.title=='Building Department Technical Exchange'
                          ||_vm.title=='Housing Authority Presentation Summary'
                          ||_vm.title=='Public Seminar in The Hong Kong Polytechnic University'
                          ||_vm.title=='International Conference and Exhibition for Trenchless Technologies and Water & Wastewater Industries 2011'
                          ||_vm.title=='Trenchless Technologies in Asia Pacific – Hong Kong 2009'
                 }]},[(_vm.title == '10th Annviversary')?_c('div',{staticClass:"pad30"},_vm._l((_vm.list),function(img,i){return _c('img',{key:i,staticClass:"twoBig",attrs:{"src":img},on:{"click":function($event){return _vm.preview(i)}}})}),0):(_vm.title == 'Technical exchange with CACP from China')?_c('div'):_c('div',{staticClass:"imgWrap clearBoth"},_vm._l((_vm.list),function(img,i){return _c('div',{key:i,style:({background:("url(" + img + ")")}),on:{"click":function($event){return _vm.preview(i)}}})}),0)]),_c('div',{class:['textBox',  { 
                width701:_vm.title=='Housing Society Seminar' 
                ||_vm.title=='Building Department Technical Exchange'
                ||_vm.title=='Housing Authority Presentation Summary'
                ||_vm.title=='Public Seminar in The Hong Kong Polytechnic University'
                ||_vm.title=='International Conference and Exhibition for Trenchless Technologies and Water & Wastewater Industries 2011'
                ||_vm.title=='Trenchless Technologies in Asia Pacific – Hong Kong 2009'
            }]},[_c('br'),_vm._l((_vm.text),function(item){return _c('div',{key:item,staticStyle:{"margin-bottom":"1em"},domProps:{"innerHTML":_vm._s(item)}})})],2)])]),_c('br'),_c('div',{staticClass:"width1000"},[_c('div',{staticClass:"sb"},[_c('div',{staticClass:"blueBtn",on:{"click":_vm.back}},[_vm._v(" < Back ")])])]),_c('div',{staticClass:"width1000"},[_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('myFooter')],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.showImgs)?_c('div',[_c('div',{staticClass:"loopMask"}),_c('div',{staticClass:"loopBox z10 "},[_c('el-carousel',{ref:"loop",staticClass:"relative z10",attrs:{"autoplay":false,"loop":"","height":"500px","initial-index":_vm.currentImg},on:{"change":_vm.loopChange}},_vm._l((_vm.list),function(item,i){return _c('el-carouselItem',{key:i},[_c('div',{staticClass:"roopImg",style:({background: ("url('" + item + "') no-repeat")})})])}),1),_c('div',{staticClass:"al miar20 sb"},[_c('div',{staticClass:"al"},[_c('div',{staticClass:"twoBtn al"},[_c('div',{on:{"click":function($event){return _vm.preNext('prev')}}}),_c('div',{on:{"click":function($event){return _vm.preNext('next')}}})]),_c('div',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.currentImg + 1)+" / "+_vm._s(_vm.list.length))])]),_c('div',{staticClass:"close ju al",on:{"click":function($event){_vm.showImgs=false}}},[_c('img',{attrs:{"src":require("@/assets/img/close.png"),"alt":""}})])])],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }